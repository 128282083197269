<template>
  <div class="d-flex flex-row w-100">
    <span class="col-3 lead collapse-title align-self-center" v-if="title !== null">{{title}}</span>
    <span class="col-3 text-muted align-self-center" v-if="authorName">{{$t('Made by')}} {{authorName}}</span>
    <span class="col-6 text-right text-muted align-self-center" :class="{'offset-3': title !== null && authorName === null}">{{moment.utc(item.date.date).local().format('HH:mm:ss')}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    authorName: {
      type: String,
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: true
    }
  },
  name: "HistoryItemHeader"
}
</script>

<style scoped>

</style>