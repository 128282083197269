<template>
  <div>
    <order-history-record :collection="collection" v-for="(collection, index) in paginatedHistory" :key="index"></order-history-record>
    <div class="d-flex justify-content-center justify-content-md-end">
      <b-pagination :total-rows="groupedHistory.length" :per-page="perPage"
                    v-if="Math.ceil(groupedHistory.length/perPage) > 1"
                    v-model="page" first-number last-number prev-class="prev-item"
                    next-class="next-item">
        <template v-slot:prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18"/>
        </template>
        <template v-slot:next-text>
          <feather-icon icon="ChevronRightIcon" size="18"/>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import OrderHistoryRecord from "@/views/cloudcart/orders/History/OrderHistoryRecord";
import {BPagination} from "bootstrap-vue";
export default {
  components: {
    OrderHistoryRecord,
    BPagination
  },
  props: {
    history: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 5
    }
  },
  name: "OrderHistory",
  data() {
    return {
      page: 1
    }
  },
  computed: {
    groupedHistory(){
      return window._.groupBy(this.history, function(item){
        return window.moment(item.date.date).set({hour: 0, minutes: 0, seconds: 0}).unix();
      });
    },
    paginatedHistory() {
      let offset = (this.perPage * this.page) - this.perPage;

      return Object.values(this.groupedHistory).slice(offset, offset + this.perPage)
    }
  }
}
</script>