<template>
  <div class="mb-2">
    <div class="row">
      <div class="col-md-6 text-muted">{{$t('Name')}}:</div>
      <div class="col-md-6">
        {{ address.firstName }} {{ address.lastName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">{{$t('Country')}}:</div>
      <div class="col-md-6">
        {{ address.countryName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">{{$t('State')}}:</div>
      <div class="col-md-6">
        {{ address.stateName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">{{$t('City')}}:</div>
      <div class="col-md-6">
        {{ address.cityName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">{{$t('Address')}}:</div>
      <div class="col-md-6">
        {{ address.streetName }} №{{ address.streetNumber }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">{{$t('Phone')}}:</div>
      <div class="col-md-6">
        {{ address.phone }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">{{$t('Postal code')}}:</div>
      <div class="col-md-6">
        {{ address.postCode }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      required: true,
      type: Object
    }
  },
  computed: {
    address() {
      return window._.get(this.order, 'address', {});
    }
  },
  name: "ShippingAddress"
}
</script>