<template>
  <div class="border-bottom">
    <app-collapse type="default">
      <app-collapse-item title="" class="border-0">
        <template v-slot:header>
          <history-item-header :title="$t('Order pending')" :item="item"></history-item-header>
        </template>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import HistoryItemHeader from "@/views/cloudcart/orders/History/elements/HistoryItemHeader";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {AppCollapse, AppCollapseItem, HistoryItemHeader},
  name: "OrderPending"
}
</script>