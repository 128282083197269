<template>
  <div class="border-bottom">
    <app-collapse type="default">
      <app-collapse-item title="" class="border-0">
        <template v-slot:header>
          <history-item-header :title="$t('Administration note changed')" :item="item"></history-item-header>
        </template>
        <div class="d-flex flex-row">
          <div class="col-md-6" v-if="!_.isEmpty(_.get(item, 'data.before.note_administrator'))">
            <div class="d-flex flex-row">
              <div class="col-6 font-weight-bold">{{$t('Changed from')}}</div>
              <div class="col-6 d-flex flex-column">
                <span>{{_.get(item, 'data.before.note_administrator')}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-flex flex-row">
              <div class="col-6 font-weight-bold">{{$t('Changed to')}}</div>
              <div class="col-6 d-flex flex-column">
                <span>{{_.get(item, 'data.after.note_administrator')}}</span>
              </div>
            </div>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import HistoryItemHeader from "@/views/cloudcart/orders/History/elements/HistoryItemHeader";
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {AppCollapse, AppCollapseItem, HistoryItemHeader},
  name: "NoteEdited"
}
</script>