<template>
  <span>
    {{ dateTime }}
  </span>
</template>

<script>

export default {
  props: {
    date: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: false,
      default: 'YYYY-MM-DD HH:mm:ss'
    }
  },
  computed: {
    dateTime() {
      let utcTime = window.moment().utc(this.date).toDate();
      return window.moment(utcTime).local().format(this.format);
    }
  },
  name: "CDate"
}
</script>