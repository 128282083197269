<template>
  <div class="mx-2 mb-2" v-if="_.get(data, 'total') > perPage">
    <b-row>

      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{$t('Showing')}} {{ data.from }} {{ $t('to') }} {{
            data.to
          }} {{ $t('of') }} {{ data.total }} {{$t('entries')}}</span>
      </b-col>
      <!-- Pagination -->
      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
            v-model="lazyPage"
            :total-rows="data.total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>
import {BPagination, BCol, BRow} from "bootstrap-vue";

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 10
    },
    page: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data() {
    return {
      lazyPage: 1
    }
  },
  created() {
    this.lazyPage = this.page
  },
  name: "CPagination",
  components: {BPagination, BCol, BRow},
  watch: {
    lazyPage(newValue) {
      this.$emit('pageChange', newValue);
    }
  }
}
</script>