<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm" no-center no-fade opacity="0.95">
      <b-table id="products-table" :items="items" :fields="tableFields" v-bind="$attrs" v-on="$listeners"
               head-variant="light"
               primary-key="id"
               :key="tableIndex"
               responsive="md">
        <template #cell(name)="data">
          <h5>{{ data.item.name }}</h5>
          <p class="small" v-if="data.item.sku">SKU: {{ data.item.sku }}</p>
          <p class="small" v-if="data.item.p1">{{ data.item.p1 }}: {{ data.item.v1 }}</p>
          <p class="small" v-if="data.item.p2">{{ data.item.p2 }}: {{ data.item.v2 }}</p>
          <p class="small" v-if="data.item.p3">{{ data.item.p3 }}: {{ data.item.v3 }}</p>
        </template>
        <template #cell(price)="data">
          <template>
            <price :value="getItemDiscountPrice(data.item)" from-cents
                   v-if="_.get(data, 'item.variant.discounts', []).length"></price>
            <price :value="data.item.price" from-cents
                   :style="{'text-decoration': _.get(data, 'item.variant.discounts', []).length ? 'line-through' : 'none'}"
                   :class="{'text-muted': _.get(data, 'item.variant.discounts', []).length}">
            </price>
          </template>
        </template>
        <template #cell(xicon)>
          <feather-icon icon="XIcon"></feather-icon>
        </template>
        <template #cell(quantity)="data">
          <template>
            {{ data.item.quantity }}
          </template>
        </template>
        <template #cell(total)="data">
          <template>
            <price :value="getItemDiscountPrice(data.item) * data.item.quantity"
                   from-cents></price>
          </template>
        </template>
        <template #cell(actions)="data">
          <template>
            <b-dropdown variant="flat-dark" class="dropdown-icon-wrapper" size="sm" right>
              <template #button-content>
                <feather-icon icon="SettingsIcon" size="25"></feather-icon>
              </template>
              <b-dropdown-item @click="discountItem = data.item" v-if="!productHasDiscount(data.item)">
                <feather-icon icon="MinusSquareIcon" class="text-success"/>
                <span class="align-middle ml-50">{{ $t('Add discount to product') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeDiscountFrom(data.item)" v-if="productHasDiscount(data.item)">
                <feather-icon icon="MinusSquareIcon" class="text-success"/>
                <span class="align-middle ml-50">{{ $t('Remove discount from product') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editingItem = data.item">
                <feather-icon icon="EditIcon" class="text-primary"/>
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>
              <c-confirmation @true="removeOrderProduct(data.item.id)">
                <template v-slot="{confirm}">
                  <b-dropdown-item @click="confirm">
                    <feather-icon icon="Trash2Icon" class="text-danger"/>
                    <span class="align-middle ml-50">{{ $t('Removal') }}</span>
                  </b-dropdown-item>
                </template>
              </c-confirmation>
            </b-dropdown>

          </template>
        </template>
      </b-table>
      <template #overlay>
        <div class="row align-items-center m-0 h-100" v-if="editingItem !== null">
          <div class="d-flex flex-column w-100">
            <div class="d-flex flex-row w-100">
              <div class="offset-md-3 col-md-3">
                <b-form-group
                    :label="$t('Quantity')"
                    label-for="input-quantity"
                >
                  <b-form-input id="input-quantity" type="number" v-model="editingItem.quantity"
                                :min="0.001"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group
                    :label="$t('Price')"
                    label-for="input-price"
                >
                  <b-form-input id="input-price" :value="editingItem.price / 100"
                                @input="v => editingItem.price = v * 100"
                                type="number" min="0.1"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex flex-row w-100 justify-content-center">
              <b-button variant="primary" class="mr-2" :disabled="editLoading" @click="updateOrderProduct">
                <b-spinner small v-if="editLoading"/>
                {{ $t('Save') }}
              </b-button>
              <b-button variant="secondary" @click="editingItem = null" :disabled="editLoading">{{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="row align-items-center m-0 h-100" v-if="discountItem !== null">
          <div class="d-flex flex-column w-100">
            <div class="d-flex flex-row w-100 justify-content-center">
              <div class="col-md-3">
                <b-form-group
                    :label="$t('Discount type')"
                    label-for="input-discount-type"
                >
                  <c-vue-select :options="[{value: 'flat', label: $t('Amount')}, {value: 'percent', label: $t('Percent')}]"
                            class="bg-white"
                            :reduce="item => item.value"
                            :clearable="false"
                            :filterable="false"
                            :searchable="false"
                            v-model="discountItem.discountType">
                  </c-vue-select>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group
                    :label="$t('Discount value')"
                    label-for="input-discount-value"
                >
                  <b-input-group
                      :append="discountAppend"
                      class="input-group-merge"
                  >
                    <b-form-input id="input-discount-value" type="number" v-model="discountItem.discountValue"
                                  :disabled="!discountItem.discountType"
                                  :min="0.01"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex flex-row w-100 justify-content-center">
              <b-button variant="primary" class="mr-2" :disabled="discountLoading" @click="setProductDiscount">
                <b-spinner small v-if="discountLoading"/>
                {{ $t('Save') }}
              </b-button>
              <b-button variant="secondary" @click="discountItem = null" :disabled="discountLoading">{{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-overlay>
    <b-row class="d-flex justify-content-center align-items-start border-top">
      <div>
        <b-dropdown
            id="dropdown-1"
            text="Primary"
            variant="primary"
            class="mt-n2"
            toggle-class="rounded-circle btn-icon"
            size="sm"
            no-caret
        >
          <template #button-content>
            <feather-icon icon="PlusIcon"/>
          </template>
          <b-dropdown-item @click="productPanel = true">
            <feather-icon icon="PlusCircleIcon" class="text-primary"></feather-icon>
            {{ $t('Add product') }}
          </b-dropdown-item>
          <b-dropdown-item>
            <feather-icon icon="MinusSquareIcon" class="text-success"></feather-icon>
            {{ $t('Add order discount') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-row>
    <add-product-panel id="add-product-panel" width="70%" @addProduct="addProduct" :visible="productPanel"
                       @hidden="productPanel = false"></add-product-panel>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BOverlay,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BSpinner
} from "bootstrap-vue";
import Price from "@/views/cloudcart/_elements/Price";
import AddProductPanel from "@/views/cloudcart/orders/Products/AddProductPanel";
import CConfirmation from "@/views/cloudcart/_elements/CConfirmation";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";
import {mapActions} from "vuex";

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  name: "ProductsList",
  components: {
    BTable,
    Price,
    BRow,
    BDropdown,
    BDropdownItem,
    AddProductPanel,
    CConfirmation,
    BOverlay,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BInputGroup,
    CVueSelect
  },
  directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      showOverlay: false,
      productPanel: false,
      editingItem: null,
      editLoading: false,
      discountItem: null,
      discountLoading: false,
      tableIndex: 0,
      tableFields: [
        {key: 'id', label: this.$t('ID')},
        {key: 'name', label: this.$t('Product')},
        {key: 'price', label: this.$t('Item price')},
        {key: 'xicon', label: ''},
        {key: 'quantity', label: this.$t('Quantity')},
        {key: 'total', label: this.$t('Total')},
        {key: 'actions', label: this.$t('Actions')}
      ]
    }
  },
  computed: {
    discountAppend() {
      return this.discountItem.discountType === 'percent' ? '%' : 'лв';
    }
  },
  methods: {
    ...mapActions('orders', ['addProductToOrder', 'removeProductFromOrder', 'updateProduct', 'updateProductDiscount', 'removeProductDiscount']),
    addProduct(product) {
      this.addProductToOrder({
        variant_id: product.id,
        quantity: product.selectedQuantity,
        price: product.priceChange ? product.selectedPrice : null
      }).then(response => {
        let listItemElement = document.querySelector('[data-pk="' + response.data.id + '"]');
        listItemElement.classList.add('animate__animated', 'animate__bounceInUp')
        setTimeout(() => {
          listItemElement.classList.remove('animate__bounceInUp')
          this.tableIndex++;
        }, 1000)

      });
    },
    updateOrderProduct() {
      if (this.editingItem === null) {
        return;
      }
      this.editLoading = true;
      this.updateProduct({
        product_id: this.editingItem.id,
        quantity: this.editingItem.quantity,
        price: this.editingItem.price
      }).then(() => {
        let listItemElement = document.querySelector('[data-pk="' + this.editingItem.id + '"]');
        this.editingItem = null;
        listItemElement.classList.add('animate__animated', 'animate__flash')
        setTimeout(() => listItemElement.classList.remove('animate__flash'), 1000)
      }).finally(() => {
        this.editLoading = false;
      })
    },
    removeOrderProduct(id) {
      let listItemElement = document.querySelector('[data-pk="' + id + '"]');
      listItemElement.classList.add('animate__animated', 'animate__bounceOutLeft');
      setTimeout(() => {
        this.removeProductFromOrder(id).catch(() => {
          listItemElement.classList.remove('animate__bounceOutLeft', 'animate__bounceInLeft')
        })
      }, 300);
    },
    setProductDiscount() {
      this.updateProductDiscount({
        product_id: this.discountItem.id,
        discount_type: this.discountItem.discountType,
        discount_value: this.discountItem.discountValue,
      }).then(() => {
        this.discountItem = null;
        this.tableIndex++;
      })
    },
    productHasDiscount(product) {
      return window._.get(product, 'variant.discounts', []).length > 0;
    },
    removeDiscountFrom(product) {
      this.removeProductDiscount({product_id: product.id}).then(() => {
        this.tableIndex++;
      });
    },
    getItemDiscountPrice(item) {
      if (!window._.get(item, 'variant.discounts', []).length) {
        return item.price;
      }
      let discountType = window._.get(item, 'variant.discounts.0.type');
      let discountValue = window._.get(item, 'variant.discounts.0.price');
      if (discountType === 'percent') {
        return item.price - (item.price * (discountValue / 100) / 100)
      }
      return item.price - discountValue;
    }
  },
  watch: {
    editingItem(newValue) {
      this.showOverlay = newValue !== null
    },
    discountItem(newValue) {
      this.showOverlay = newValue !== null
    }
  }
}
</script>
<style lang="scss">
.b-overlay > .position-absolute:nth-child(2) {
  width: 100%;
}
</style>