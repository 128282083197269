<template>
  <b-form>
    <div class="row">
      <div class="col-md-6">
        <c-form-input :label="$t('First name')" v-model="address.firstName" :placeholder="$t('For example Angel')"
                      required></c-form-input>
      </div>
      <div class="col-md-6">
        <c-form-input :label="$t('Last name')" v-model="address.lastName" :placeholder="$t('For example Ivanov')"
                      required></c-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <c-country-picker :label="$t('Country')" v-model="address.countryId" :reduce="v => v.code"
                          id="country-picker"></c-country-picker>
      </div>
      <div class="col-md-4">
        <c-form-input :label="$t('State')" v-model="address.stateName" :placeholder="$t('State')"
                      required></c-form-input>
      </div>
      <div class="col-md-4">
        <c-form-input :label="$t('City')" v-model="address.cityName" :placeholder="$t('City')" required></c-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <c-form-input :label="$t('In this field enter the street name')" v-model="address.streetName" required
                      class="card p-2" input-bg="bg-light"></c-form-input>
      </div>
      <div class="col-md-4">
        <c-form-input :label="$t('Street number')" v-model="address.streetNumber" required class="card p-2"
                      input-bg="bg-light"></c-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <c-form-input :label="$t('Additional address information')" v-model="address.note"
                      class="card p-2" input-bg="bg-light"
                      :placeholder="$t('Additional information such as: building number, entrance, level, apartment')">
        </c-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <c-form-input :label="$t('City postal code')" v-model="address.postCode" class="card p-2" required
                      input-bg="bg-light"></c-form-input>
      </div>
      <div class="col-md-6">
        <c-form-input :label="$t('Phone number')" v-model="address.phone" class="card p-2" required
                      input-bg="bg-light"></c-form-input>
      </div>
    </div>
  </b-form>
</template>

<script>
import {BForm} from "bootstrap-vue";
import CCountryPicker from "@/views/cloudcart/_elements/CCountryPicker";
import CFormInput from "@/views/cloudcart/_elements/CFormInput";
import {mapActions} from "vuex";

export default {
  name: "ShippingAddressForm",
  components: {
    BForm,
    CCountryPicker,
    CFormInput
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    },
    order_id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      address: {}
    }
  },
  methods: {
    ...mapActions('shipping', ['get']),
  },
  created() {
    this.get({
      order_id: this.order_id,
      shipping_id: this.id
    }).then(response => {
      this.address = response.data;
    })
  },
  watch: {
    id() {
      this.get({
        order_id: this.order_id,
        shipping_id: this.id
      }).then(response => {
        this.address = response.data;
      })
    },
    address(newValue) {
      this.$emit('input', newValue);
    }
  }
}
</script>
