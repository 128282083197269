<template>
  <b-form-group
      :label="label"
      :description="description"
      :label-for="_uid.toString()"
      :valid-feedback="validFeedback"
      :invalid-feedback="invalidFeedback"
      :state="state"
  >
    <c-vue-select
        :class="inputBg"
        :id="_uid.toString()"
        v-bind="$attrs"
        v-on="$listeners"
        label="name"
        :options="countries.countries"
    >
      <template v-slot:option="{option}">
        <i class="flag" :class="'flag-' + _.get(option, 'code', '').toLowerCase()"></i>
        {{ option.name }}
      </template>
    </c-vue-select>
  </b-form-group>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {BFormGroup} from "bootstrap-vue";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";

export default {
  name: "CCountryPicker",
  props: {
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    validFeedback: {
      type: String,
      required: false
    },
    invalidFeedback: {
      type: String,
      required: false
    },
    state: {
      type: Boolean,
      required: false,
      default: null
    },
    inputBg: {
      type: String,
      required: false,
      default: 'bg-white'
    }
  },
  components: {CVueSelect, BFormGroup},
  computed: {
    ...mapState([
      'countries'
    ])
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions('countries', ['fetch'])
  }
}
</script>