<template>
  <div>
    <h4 class="text-center">{{customer.firstName}} {{customer.lastName}}</h4>
    <h6 class="text-center">{{customer.email}}</h6>
    <hr>
    <div class="d-flex flex-row mb-2">
      <div class="col">
        {{$t('Notify customer')}}
      </div>
      <div>
        <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
        >
        <span class="switch-icon-left">
          <feather-icon icon="BellIcon" />
        </span>
          <span class="switch-icon-right">
          <feather-icon icon="BellOffIcon" />
        </span>
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox} from "bootstrap-vue";
export default {
  props: {
    customer: {
      type: Object,
      required: true
    },
  },
  components: {
    BFormCheckbox
  },
  name: "CustomerInfo"
}
</script>

<style scoped>

</style>