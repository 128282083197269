<template>
  <div class="border-bottom">
    <app-collapse type="default">
      <app-collapse-item title="" class="border-0">
        <template v-slot:header>
          <history-item-header :title="$t('Product added')" :item="item"></history-item-header>
        </template>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Product quantity')}}</div>
            <div class="col p-1">{{item.data.order_product.quantity}}</div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Product name')}}</div>
            <div class="col p-1">{{_.get(item, 'data.order_product.name', 'N/A')}}</div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('SKU')}}</div>
            <div class="col p-1">{{_.get(item, 'data.order_product.sku', 'N/A')}}</div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Barcode')}}</div>
            <div class="col p-1">{{_.get(item, 'data.order_product.barcode', 'N/A')}}</div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Weight')}}</div>
            <div class="col p-1">{{_.get(item, 'data.order_product.weight', 'N/A')}}</div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Vendor')}}</div>
            <div class="col p-1">{{_.get(item, 'data.order_product.vendor_name', 'N/A')}}</div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Sale')}}</div>
            <div class="col p-1">{{_.get(item, 'data.order_product.sale', 'N/A')}}</div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Product price')}}</div>
            <div class="col p-1"><price :value="item.data.order_product.price" from-cents></price></div>
          </div>

          <div class="d-flex flex-row border">
            <div class="col border-right p-1">{{$t('Added')}}</div>
            <div class="col p-1">{{moment(item.date.date).format('DD.MM.Y HH:mm')}}</div>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Price from "@/views/cloudcart/_elements/Price";
import HistoryItemHeader from "@/views/cloudcart/orders/History/elements/HistoryItemHeader";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {AppCollapse, AppCollapseItem, Price, HistoryItemHeader},
  name: "ProductAdded",
  data() {
    return {

    }
  }
}
</script>