<template>
  <div class="mb-2">
    <div class="row">
      <div class="col-md-6 text-muted">Име:</div>
      <div class="col-md-6">
        {{ address.firstName }} {{ address.lastName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">Държава:</div>
      <div class="col-md-6">
        {{ address.countryName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">Област:</div>
      <div class="col-md-6">
        {{ address.stateName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">Град:</div>
      <div class="col-md-6">
        {{ address.cityName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">Адрес:</div>
      <div class="col-md-6">
        {{ address.streetName }} №{{ address.streetNumber }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">Телефон:</div>
      <div class="col-md-6">
        {{ address.phone }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-muted">Пощенски код:</div>
      <div class="col-md-6">
        {{ address.postCode }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      required: true,
      type: Object
    }
  },
  name: "BillingAddress"
}
</script>