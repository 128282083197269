<template>
  <div class="col w-100 card py-2 border-top border-bottom shadow-lg mx-1">
    <h4 class="mb-2">{{ $t('Add product') }}</h4>
    <div class="d-flex flex-row" v-if="variants.length > 1">

      <div class="col-md-4">
        <b-form-group
            :label="$t('Material')"
            label-for="select-material"
        >
          <c-vue-select id="select-material" :options="materialItems"
                    v-model="selectedMaterial"></c-vue-select>
        </b-form-group>
      </div>
      <div class="col-md-4">
        <b-form-group
            :label="$t('Color')"
            label-for="select-color"
        >
          <c-vue-select id="select-color" :options="colorItems" v-model="selectedColor" :placeholder="$t('Color')"
                    :disabled="_.isNull(selectedMaterial) || _.isEmpty(_.first(colorItems))"></c-vue-select>
        </b-form-group>
      </div>
      <div class="col-md-4">
        <b-form-group
            :label="$t('Size')"
            label-for="select-size"
        >
          <c-vue-select id="select-size" :options="sizeItems" :placeholder="$t('Size')" v-model="selectedSize"
                    :disabled="_.isNull(selectedMaterial) || _.isNull(selectedColor) || _.isEmpty(_.first(sizeItems))"
          ></c-vue-select>
        </b-form-group>
      </div>

    </div>

    <div v-if="filteredItems.length === 1" class="px-1 mt-2">
      <b-table :items="firstItemTableAttributes" :fields="itemTableFields">
        <template #cell(price)="data">
          <price :value="data.item.price" from-cents></price>
        </template>
        <template #cell(weight)="data">
          <span v-if="_.isNumber(data.item.weight)">
            {{
              new Intl.NumberFormat('bg-BG', {
                style: 'unit',
                unit: 'kilogram',
                minimumFractionDigits: 3
              }).format(data.item.weight / 1000)
            }}
          </span>
          <span v-else>
            {{data.item.weight}}
          </span>
        </template>
      </b-table>
      <div class="d-flex flex-row row">
        <div class="col-md-6">
          <b-form-group
              :label="$t('Quantity')"
              label-for="input-quantity"
          >
            <b-form-input id="input-quantity" type="number" v-model="firstItem.selectedQuantity"
                          :min="0.001"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
              :label="$t('Price')"
              label-for="input-price"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox
                    v-model="firstItem.priceChange"
                    plain
                    class="mr-1"
                />
                {{ $t('Change price') }}
              </b-input-group-prepend>
              <b-form-input id="input-price" :disabled="!firstItem.priceChange" v-model="firstItem.selectedPrice"
                            type="number" min="0.1"></b-form-input>
            </b-input-group>
          </b-form-group>

        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-end">
      <b-button variant="secondary" class="mr-1" @click="$emit('dismiss')">{{ $t('Close') }}</b-button>
      <b-button variant="primary" v-if="filteredItems.length === 1" @click="addProduct">{{ $t('Add') }}</b-button>
    </div>
  </div>
</template>

<script>
import {BTable, BFormGroup, BFormInput, BInputGroupPrepend, BFormCheckbox, BInputGroup, BButton} from "bootstrap-vue";
import Price from "@/views/cloudcart/_elements/Price";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";

export default {
  name: "ProductVariantSelector",
  components: {CVueSelect, BTable, Price, BFormGroup, BFormInput, BInputGroupPrepend, BFormCheckbox, BInputGroup, BButton},
  props: {
    variants: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      selectedMaterial: null,
      selectedColor: null,
      selectedSize: null,
      itemTableFields: [
        {label: this.$t('SKU'), key: 'sku'},
        {label: this.$t('Barcode'), key: 'barcode'},
        {label: this.$t('Price'), key: 'price'},
        {label: this.$t('Quantity'), key: 'quantity'},
        {label: this.$t('Weight'), key: 'weight'}
      ],
    }
  },
  computed: {
    firstItem() {
      let item = window._.first(this.filteredItems)
      item.selectedQuantity = 1;
      return item;
    },
    firstItemTableAttributes() {
      return [
        {
          sku: window._.get(this.firstItem, 'sku') || this.$t('Not available'),
          barcode: window._.get(this.firstItem, 'barcode') || this.$t('Not available'),
          price: window._.get(this.firstItem, 'price') || this.$t('Not available'),
          quantity: window._.get(this.firstItem, 'quantity') || '∞',
          weight: window._.get(this.firstItem, 'weight') || this.$t('Not available'),
        }
      ];
    },
    filteredItems() {
      return window._.filter(this.variants, item => {
        return (this.selectedMaterial !== null && item.v1 === this.selectedMaterial || item.v1 === null)
            && (this.selectedColor !== null && item.v2 === this.selectedColor || item.v2 === null)
            && (this.selectedSize !== null && item.v3 === this.selectedSize || item.v3 === null)
      })
    },
    materialItems() {
      return window._.map(this.variants, 'v1');
    },
    colorItems() {
      return window._.map(this.filteredItems, 'v2');
    },
    sizeItems() {
      return window._.map(this.filteredItems, 'v3');
    }
  },
  methods: {
    addProduct(){
      this.$emit('addProduct', this.firstItem)
      this.$emit('dismiss')
    }
  }
}
</script>