<template>
  <div>
    <c-navbar v-if="!hideNavbar">
      <template v-slot:title>
        {{ $t('Products') }}
      </template>
      <template v-slot:buttons>
        <b-button variant="outline-primary" class="mr-2">
          <feather-icon class="mr-1" icon="DownloadCloudIcon"/>
          CSV шаблони
        </b-button>
        <b-button variant="secondary" class="mr-2">Импортирай продукти</b-button>
        <router-link :to="{name: 'products.create'}">
          <b-button variant="primary" class="mr-2">Добави продукт (1 от 500)</b-button>
        </router-link>
      </template>
    </c-navbar>
    <b-card no-body>
      <div class="m-2">
        <c-table-filters :available-filters="products.filters"
                         module="productsList"
                         allow-search
                         @search="v => searchQuery = v"
                         @filtersUpdate="v => tableFilters = v"></c-table-filters>
      </div>
    </b-card>
    <b-card
        no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-dropdown :text="$t('Tools')" variant="primary" id="settingsDropdown" size="md"
                        :disabled="!selectedRows.length">
              <b-dropdown-item>
                <feather-icon icon="UploadCloudIcon"></feather-icon>
                {{ $t('Publish') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="CopyIcon"></feather-icon>
                {{ $t('Duplicate') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="StopCircleIcon"></feather-icon>
                {{ $t('Stop') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="StarIcon"></feather-icon>
                {{ $t('Mark as new') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="XIcon"></feather-icon>
                {{ $t('Remove status new') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="Trash2Icon"></feather-icon>
                {{ $t('Delete') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="EyeOffIcon"></feather-icon>
                {{ $t('Disable tracking') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="EyeIcon"></feather-icon>
                {{ $t('Enable tracking') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TagIcon"></feather-icon>
                {{ $t('Set tags') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="EyeIcon"></feather-icon>
                {{ $t('Change main category') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="ArrowUpCircleIcon"></feather-icon>
                {{ $t('Change manufacturer') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="InfoIcon"></feather-icon>
                {{ $t('Set status to available') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="XCircleIcon"></feather-icon>
                {{ $t('Set status to unavailable') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="ListIcon"></feather-icon>
                {{ $t('Set sorting') }}
              </b-dropdown-item>
            </b-dropdown>
            <c-vue-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>

      </div>
    <c-table
        ref="refProductsListTable"
        :items="products.data"
        has-selection
        selectable
        head-variant="light"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        class="position-relative"
        @rowSelected="v => selectedRows = v"
    >
      <template v-for="field in Object.keys($scopedSlots)" v-slot:[field]="{item}">
        <slot :name="field" :item="item"/>
      </template>
      <template #cell(id)="data">
        <div class="d-flex flex-row">
          <div>
            <b-button variant="outline-secondary" class="btn-icon rounded-circle">
              <feather-icon icon="BoxIcon" />
            </b-button>
          </div>
          <div class="d-flex flex-column">
            <div class="col">{{data.item.name}}</div>
            <div class="col" v-if="data.item.vendor">От {{data.item.vendor.name}}</div>
          </div>
        </div>
      </template>
      <template #cell(variants)="data">
        <b-button v-if="data.item.variants.length > 1" variant="outline-secondary" size="sm">{{$t('Variants')}}</b-button>
        <span v-else>{{$t('No variants')}}</span>
      </template>
      <template #cell(dateAdded)="data">
        <div class="d-flex flex-column">
          <c-date :date="_.get(data, 'item.dateModified.date')" format="DD.MM.Y"></c-date>
          <c-date :date="_.get(data, 'item.dateModified.date')" format="HH:mm" class="text-muted"></c-date>
        </div>
      </template>
      <template #cell(dateModified)="data">
        <div class="d-flex flex-column">
          <c-date :date="_.get(data, 'item.dateModified.date')" format="DD.MM.Y"></c-date>
          <c-date :date="_.get(data, 'item.dateModified.date')" format="HH:mm" class="text-muted"></c-date>
        </div>
      </template>
      <template #head(actions)>
        <span></span>
      </template>
      <template #cell(actions)="data">
        <feather-icon :icon="data.item.isHidden ? 'CheckCircleIcon' : 'XCircleIcon'"
                      :class="{'text-danger': !data.item.isHidden, 'text-success': data.item.isHidden}"
                      v-b-tooltip.hover.top="data.item.isHidden ? $t('Hidden') : $t('Visible')"
                      size="22" class="mr-1"
        ></feather-icon>
        <feather-icon :icon="data.item.isFeatured ? 'CheckCircleIcon' : 'XCircleIcon'"
                      v-b-tooltip.hover.top="data.item.isFeatured ? $t('Featured') : $t('Not featured')"
                      :class="{'text-danger': !data.item.isFeatured, 'text-success': data.item.isFeatured}"
                      size="22" class="mr-1"
        ></feather-icon>
        <feather-icon :icon="data.item.isNew === 'yes' ? 'CheckCircleIcon' : 'XCircleIcon'"
                      v-b-tooltip.hover.top="data.item.isNew === 'yes' ? $t('Marked as new') : $t('Not marked as new')"
                      :class="{'text-danger': data.item.isNew !== 'yes', 'text-success': data.item.isNew === 'yes'}"
                      size="22" class="mr-1"
        ></feather-icon>
        <feather-icon :icon="data.item.isActive ? 'CheckCircleIcon' : 'XCircleIcon'"
                      v-b-tooltip.hover.top="data.item.isActive ? $t('Published') : $t('Not published')"
                      :class="{'text-danger': !data.item.isActive, 'text-success': data.item.isActive}"
                      size="22" class="mr-1"
        ></feather-icon>
      </template>
    </c-table>
      <c-pagination :data="products" :per-page="perPage" :page="page"
                    @pageChange="v => page = v"></c-pagination>
    </b-card>
  </div>
</template>

<script>
import CNavbar from "@/views/cloudcart/_elements/CNavbar";
import CTable from "@/views/cloudcart/_elements/CTable";
import CPagination from "@/views/cloudcart/_elements/CPagination";
import {BButton, BCard, BDropdown, BDropdownItem, BRow, BCol, VBTooltip} from "bootstrap-vue";
import {mapActions} from "vuex";
import CDate from "@/views/cloudcart/_elements/CDate";
import CTableFilters from "@/views/cloudcart/_elements/CTableFilters"
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";
export default {
  props: {
    hideNavbar: {
      type: Boolean,
      default: false
    }
  },
  name: "ProductsList",
  components: {
    CNavbar, BButton, CTable, CPagination, CDate, CTableFilters, CVueSelect,
    BCard, BDropdown, BDropdownItem, BCol, BRow
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  computed: {
    products() {
      return this.$store.getters['products/paginatedProducts']
    }
  },
  data() {
    return {
      tableColumns: [
        {key: 'selection', sortable: false, label: this.$t('Selection')},
        { key: 'id', label: this.$t('Product'), sortable: true },
        { key: 'variants', label: this.$t('Variants'), sortable: false },
        { key: 'quantity', label: this.$t('Quantity'), sortable: false },
        { key: 'sortOrder', label: this.$t('Sorting'), sortable: false },
        { key: 'dateAdded', label: this.$t('Added on'), sortable: false },
        { key: 'dateModified', label: this.$t('Modified on'), sortable: false },
        { key: 'actions', label: this.$t('Status'), sortable: false },
      ],
      perPageOptions: [10, 25, 50, 100],
      tableFilters: [],
      perPage: 10,
      page: 1,
      searchQuery: null,
      selectedRows: []
    }
  },
  methods: {
    ...mapActions('products', ['fetchProducts']),
  },
  created() {
    this.fetchProducts({perPage: this.perPage, page: this.page, filters: this.tableFilters});
  },
  watch: {
    perPage(newValue) {
      this.fetchProducts({perPage: newValue, page: this.page, filters: this.tableFilters, filter: this.searchQuery});
    },
    page(newValue) {
      this.fetchProducts({perPage: this.perPage, page: newValue, filters: this.tableFilters, filter: this.searchQuery});
    },
    searchQuery: window._.debounce(function (newValue) {
      this.fetchProducts({
        perPage: this.perPage,
        page: 1,
        filter: newValue,
        filters: this.tableFilters
      });
    }, 300),
    tableFilters(newValue) {
      this.fetchProducts({
        filters: newValue
      })
    }
  }
}
</script>