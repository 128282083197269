<template>
  <b-sidebar
      bg-variant="white"
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
  >
    <div class="p-2">
      <b-overlay :show="showOverlay" rounded="sm" @shown="onOverlayShown" @hidden="onOverlayHidden" no-center>
        <products-list hide-navbar>
          <template #cell(actions)="data">
            <b-button @click="addProductToOrder(data.item)" size="sm" variant="flat-primary" class="text-nowrap">
              <feather-icon icon="PlusIcon"></feather-icon>
              <span class="align-middle">{{ $t('Add product') }}</span>
            </b-button>
          </template>
        </products-list>
        <template #overlay>
          <div class="row align-items-center m-0 h-100">
            <product-variant-selector :variants="_.get(selectedProduct, 'variants', [])"
                                      @dismiss="showOverlay = false"
                                      @addProduct="v => $emit('addProduct', v)"
            ></product-variant-selector>
          </div>
        </template>
      </b-overlay>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BButton, BOverlay} from "bootstrap-vue";
import ProductsList from "@/views/cloudcart/products/ProductsList";
import ProductVariantSelector from "@/views/cloudcart/orders/Products/ProductVariantSelector";
export default {
  name: "AddProductPanel",
  components: {BSidebar, ProductsList, BButton, BOverlay, ProductVariantSelector},
  data() {
    return {
      showOverlay: false,
      selectedProduct: null
    };
  },
  methods: {
    addProductToOrder(productItem) {
      this.selectedProduct = productItem;
      this.showOverlay = true;
    },
    onOverlayShown() {

    },
    onOverlayHidden() {
    }
  }

}
</script>

<style scoped>
.b-overlay > .position-absolute:nth-child(2) {
  width: 100%;
}
</style>