<template>
  <div>
    <slot v-bind="{confirm: confirm}">

    </slot>
  </div>
</template>

<script>
import VueI18n from "@/libs/i18n/index"
export default {
  name: "CConfirmation",
  props: {
    title: {
      type: String,
      default: () => {
        return VueI18n.t('Are you sure?')
      }
    },
    text: {
      type: String,
      default: () => {
        return VueI18n.t('You won\'t be able to revert this!')
      }
    },
    icon: {
      type: String,
      default: 'warning'
    },
    cancelButton: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: () => {
        return VueI18n.t('Yes')
      }
    },
    cancelText: {
      type: String,
      default: () => {
        return VueI18n.t('Cancel')
      }
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    confirmButtonClass: {
      type: String,
      default: 'btn btn-primary'
    },
    cancelButtonClass: {
      type: String,
      default: 'btn btn-outline-danger ml-1 text-danger'
    }
  },
  methods: {
    confirm() {
      let swalOptions = {
        title: this.title,
        text: this.text,
        icon: this.icon,
        showCancelButton: this.cancelButton,
        confirmButtonText: this.confirmText,
        cancelButtonText: this.cancelText,
        customClass: {
          confirmButton: this.confirmButtonClass,
          cancelButton: this.cancelButtonClass,
        }
      }
      let options = Object.assign(swalOptions, this.options)
      this.$swal(options).then(result => {
        result.value ? this.$emit('true') : this.$emit('false')
      })
    }
  }
}
</script>