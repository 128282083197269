<template>
  <div :id="'filtersContainer' + _uid" class="d-flex flex-column">
    <div class="d-flex flex-row">
      <b-button
          :id="'filtersPopover' + _uid"
          variant="primary"
      >{{ $t('Filters') }}
      </b-button>
      <b-popover
          ref="popover"
          :target="'filtersPopover' + _uid"
          triggers="click"
          :show.sync="popoverShow"
          placement="bottomRight"
          :container="'filtersContainer' + _uid"
          class="filterPopover"
          :title="$t('Filter criteria')"
      >
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="col pl-0">
              <c-vue-select class="filterInput" v-model="selectedFilter" label="label" :options="availableFilters"
                        :placeholder="$t('Choose filter')"></c-vue-select>
            </div>
            <div class="col" v-for="field in _.get(selectedFilter,'fields', [])" :key="field.name">
              <c-vue-select v-if="field.input.type === 'select'" class="filterInput" v-model="field.input.value"
                        label="label"
                        :reduce="v => v.value" :options="field.input.options" :placeholder="field.label"></c-vue-select>
            </div>
            <div class="col" v-if="_.get(selectedFilter, 'fields.0.input.value') !== null">
              <b-form-input v-model="valueField.input.value" :placeholder="valueField.label"
                            v-if="_.get(valueField, 'input.type') === 'text'"></b-form-input>
              <flat-pickr
                  v-model="valueField.input.value"
                  v-if="_.get(valueField, 'input.type') === 'date'"
                  class="form-control"
              />
            </div>

          </div>
          <div>
            <b-button variant="primary" class="mt-2" :disabled="isAddDisabled" @click="addFilter">
              {{ $t('Add filter') }}
            </b-button>
          </div>
        </div>
      </b-popover>
      <b-form-input v-if="allowSearch"
                    v-model="searchQuery"
                    type="search"
                    class="d-inline-block ml-1"
                    :placeholder="$t('Search...')"
      />
    </div>
    <div class="d-flex flex-row align-items-center mt-1">
      <div class="mr-1">
        <c-vue-select :options="storedFilters" class="filterInput" label="name"
                  v-model="selectedStoredFilter"
                  :placeholder="storedFilters.length ? $t('Stored filters') : $t('No stored filters')"
                  :disabled="!storedFilters.length">
          <template #no-options>
            {{ $t('No stored filters') }}
          </template>
        </c-vue-select>
      </div>
      <div v-for="(filter, index) in filters" :key="filter.class" class="mr-1 font-small-2">
        <div class="border d-flex flex-row align-items-center used-filter">
          <div>
            {{ filter.label }}
            {{ _.get(filter, 'fields.0.input.value') }}
            {{ _.get(filter, 'fields.0.fields.0.input.value') }}
          </div>
          <b-button-close class="ml-1" @click="filters.splice(index, 1)"></b-button-close>
        </div>
      </div>
      <div v-if="filters.length">
        <b-button variant="secondary" @click="prepareForStoreFilter">{{ $t('Save filter') }}</b-button>
      </div>
      <div v-if="filters.length">
        <b-button variant="default" @click="clearFilters">{{ $t('Clear filters') }}</b-button>
      </div>
      <div class="flex-grow-1 text-right" v-if="selectedStoredFilter !== null">
        <c-confirmation @true="deleteStoredFilter">
          <template #default="{confirm}">
            <b-button variant="danger" @click="confirm">{{ $t('Delete selected filter') }}</b-button>
          </template>
        </c-confirmation>
      </div>
    </div>
    <b-modal
        v-model="storeFilterModal"
        :title="$t('Save filter')"
        :cancel-title="$t('Cancel')"
        :ok-title="$t('Save filter')"
        @ok="storeFilter"
    >
      <b-card-text>
        <b-form-group :label="$t('Name')">
          <b-form-input :placeholder="$t('Enter filter name')" v-model="filterName"></b-form-input>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {BPopover, BButton, BFormInput, BFormGroup, BCardText, BButtonClose} from "bootstrap-vue";
import flatPickr from 'vue-flatpickr-component'
import {mapActions} from "vuex";
import CConfirmation from "@/views/cloudcart/_elements/CConfirmation";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";

export default {
  name: "CTableFilters",
  components: {
    BPopover, BButton, BFormInput, flatPickr,
    BFormGroup, BCardText, BButtonClose,
    CConfirmation, CVueSelect
  },
  props: {
    availableFilters: {
      type: Array,
      default: () => {
        return []
      }
    },
    module: {
      type: String,
      required: true
    },
    allowSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverShow: false,
      selectedFilter: {},
      filters: [],
      filterName: null,
      storeFilterModal: false,
      selectedStoredFilter: null,
      searchQuery: null
    }
  },
  computed: {
    storedFilters() {
      return this.$store.state.filters.filters;
    },
    valueField() {
      return window._.get(this.selectedFilter, 'fields.0.fields.0', {});
    },
    isAddDisabled() {
      if (!window._.get(this.selectedFilter, 'class')) {
        return true;
      }

      if (window._.isEmpty(window._.get(this.selectedFilter, 'fields.0.input.value'))) {
        return true;
      }

      if (window._.get(this.valueField, 'input') && window._.isEmpty(window._.get(this.valueField, 'input.value'))) {
        return true;
      }

      return false;
    }
  },
  created() {
    this.fetchFilters({
      params: {
        module: this.module
      }
    })
  },
  methods: {
    ...mapActions('filters', ["fetchFilters", "saveFilter", "deleteFilter"]),
    addFilter() {
      let existingIndex = window._.findIndex(this.filters, {class: this.selectedFilter.class});
      if (existingIndex === -1) {
        this.filters.push(this.selectedFilter);
      }
      this.popoverShow = false;
      this.selectedFilter = {};
    },
    storeFilter() {
      this.saveFilter({
        module: this.module,
        name: this.filterName,
        data: this.filters
      })
    },
    clearFilters() {
      this.filters = []
      this.selectedStoredFilter = null
    },
    prepareForStoreFilter() {
      if (this.selectedStoredFilter === null) {
        this.storeFilterModal = true;
        return;
      }
      this.saveFilter({
        module: this.module,
        name: this.selectedStoredFilter.name,
        data: this.filters,
        id: this.selectedStoredFilter.id
      })
    },
    deleteStoredFilter() {
      this.deleteFilter(this.selectedStoredFilter.id)
          .then(() => {
            this.clearFilters()
          })
    }
  },
  watch: {
    filters: function (newValue) {
      this.$emit('filtersUpdate', newValue)
    },
    selectedStoredFilter: function (newValue) {
      if (newValue !== null) {
        this.filters = newValue.data;
      }
    },
    searchQuery: function (newValue) {
      this.$emit('search', newValue)
    }
  }
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.popover {
  min-width: 200px;
  max-width: none;
}

.filterInput {
  min-width: 250px;
}

.used-filter {
  padding: 0.286rem 0.9rem;
}
</style>