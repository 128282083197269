<template>
  <b-card class="p-0">
    <b-card-title class="px-2 pt-1 mb-0 font-medium-1 border-bottom pb-1">{{moment(_.first(collection).date.date).format('DD.MM.Y')}}</b-card-title>
      <div v-for="item in collection" :key="item.id">
        <note-edited v-if="item.message === 'order_notes_edit'" :item="item"></note-edited>
        <product-added v-else-if="item.message === 'following_product_added'" :item="item"></product-added>
        <shipping-change v-else-if="item.message === 'order_shipping_change'" :item="item"></shipping-change>
        <order-paid v-else-if="item.message === 'order_paid'" :item="item"></order-paid>
        <order-pending v-else-if="item.message === 'order_pending'" :item="item"></order-pending>
        <order-discount-added v-else-if="item.message === 'discount_added_for_order'" :item="item"></order-discount-added>
        <div v-else>//TODO {{item.message}}</div>
      </div>
  </b-card>
</template>

<script>
import {BCard, BCardTitle} from "bootstrap-vue";
import NoteEdited from "@/views/cloudcart/orders/History/Types/NoteEdited";
import ProductAdded from "@/views/cloudcart/orders/History/Types/ProductAdded";
import ShippingChange from "@/views/cloudcart/orders/History/Types/ShippingChange";
import OrderPaid from "@/views/cloudcart/orders/History/Types/OrderPaid";
import OrderPending from "@/views/cloudcart/orders/History/Types/OrderPending";
import OrderDiscountAdded from "@/views/cloudcart/orders/History/Types/OrderDiscountAdded";

export default {
  props: {
    collection: {
      type: Array,
      required: true
    }
  },
  components: {
    BCard,BCardTitle,

    NoteEdited,
    ProductAdded,
    ShippingChange,
    OrderPaid,
    OrderPending,
    OrderDiscountAdded
  },
  name: "OrderHistoryRecord"
}
</script>
<style scoped>
.card > .card-body {
  padding:1px;
}
</style>