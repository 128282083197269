<template>
  <div class="border-bottom">
    <app-collapse type="default">
      <app-collapse-item title="" class="border-0">
        <template v-slot:header>
          <history-item-header :title="$t('Discount added to order')" :item="item"></history-item-header>
        </template>
        <div>
          <strong>{{$t('Manual discount')}}:</strong>
          <span v-if="item.data.order_discount.type === 'percent'"> %{{_.get(item, 'data.order_discount.type_value') / 100}}</span>
          <span v-else> {{_.get(item, 'data.order_discount.type_value') / 100}}</span>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import HistoryItemHeader from "@/views/cloudcart/orders/History/elements/HistoryItemHeader";
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  name: "OrderDiscountAdded",
  components: {AppCollapse, AppCollapseItem, HistoryItemHeader}
}
</script>