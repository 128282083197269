<template>
  <section>
    <c-navbar>
      <template v-slot:title>
        {{ $t("Order #{id}", {id: $route.params.id}) }}
      </template>
    </c-navbar>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb
            :items="breadCrumbs"
        />
      </b-col>
    </b-row>
    <b-overlay
        id="overlay-background"
        :show="!orderLoaded"
        variant="transparent"
        opacity="0.85"
        blur="2px"
        rounded="sm"
    >
      <b-row>
        <b-col
            cols="12"
            xl="9"
            md="8"
        >
          <b-card
              no-body
          >
            <b-card-body class="pb-0">
              <products-list :items="order.products"></products-list>
              <b-row>
                <b-col cols="12" md="6">
                  <div class="form-label-group">
                    <b-form-textarea
                        id="administrationNote"
                        :placeholder="$t('Administration note')"
                        rows="2"
                        max-rows="5"
                    />
                    <label for="administrationNote">{{ $t('Administration note') }}</label>
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <b-row>
                    <b-col cols="6" class="font-weight-bold">
                      {{ $t('Subtotal') }}
                    </b-col>
                    <b-col cols="6" class="text-right font-weight-bold">
                      <price :value="order.priceSubtotal" from-cents></price>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="6">
                      <span class="font-weight-bold">{{ $t('Delivery') }}</span>
                      <p class="mb-0"><small class="text-muted">Тегло: 1.000 кг.</small></p>
                      <p class="mb-0"><small class="text-muted">Платец: Получател</small></p>
                    </b-col>
                    <b-col cols="6" class="text-right font-weight-bold">
                      <price :value="500" from-cents></price>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1 pt-1 mb-2 font-medium-1 border-top font-weight-bolder">
                    <b-col cols="6">
                      {{ $t('Total') }}
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <price :value="order.priceTotal" from-cents></price>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="border-top py-1">
                <b-col cols="12" md="6">
                  <div class="d-flex flex-md-row flex-column gx-2 align-items-center">
                    <div class="col flex-grow-0">
                      <feather-icon icon="CreditCardIcon" size="23" class="d-none d-md-block text-muted"></feather-icon>
                    </div>
                    <div class="col flex-grow-0 text-uppercase font-weight-bold">{{ $t('Payment') }}</div>
                    <b-form-group class="flex-grow-1 col mb-0">
                      <c-vue-select
                          v-model="selectedPaymentMethod"
                          label="title"
                          value="value"
                          :filterable="false"
                          :clearable="false"
                          :options="paymentMethods"
                          :placeholder="$t('Select payment method')"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="12" md="6" class="text-md-right">
                  <b-button variant="outline-dark">{{ $t('Mark order as paid') }}</b-button>
                </b-col>
              </b-row>
              <b-row class="border-top py-1">
                <b-col cols="12" md="6">
                  <div class="d-flex flex-md-row flex-column gx-2 align-items-center">
                    <div class="col flex-grow-0">
                      <feather-icon icon="TruckIcon" size="23" class="d-none d-md-block text-muted"></feather-icon>
                    </div>
                    <div class="col flex-grow-0 text-uppercase font-weight-bold">{{ $t('Delivery') }}</div>
                    <b-form-group class="flex-grow-1 col mb-0">
                      <c-vue-select
                          v-model="selectedDeliveryMethod"
                          label="title"
                          value="value"
                          :filterable="false"
                          :clearable="false"
                          :options="deliveryMethods"
                          :placeholder="$t('Select delivery method')"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="12" md="6" class="text-md-right">
                  <b-button variant="primary">{{ $t('Prepare for delivery') }}</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-row v-if="_.get(order, 'history', []).length">
            <b-col>
              <h2 class="text-muted">{{ $t('Order history') }}</h2>
              <order-history :history="order.history"></order-history>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" xl="3" md="4">
          <b-card
              no-body
          >
            <b-card-body class="pb-0">
              <customer-info :customer="order.customer"></customer-info>
            </b-card-body>
          </b-card>

          <b-card
              no-body
              v-if="order.shipping"
          >
            <b-card-header>
              <b-card-title class="row align-content-center w-100">
                <span class="col-auto align-self-center flex-grow-1">{{ $t('Shipping address') }}</span>
                <span class="col-auto align-self-center flex-grow-0 px-0">
                  <b-button class="btn-icon rounded-circle" variant="outline-primary" size="sm"
                            @click="shippingPanel = true"
                  >
                    <feather-icon icon="EditIcon"></feather-icon>
                  </b-button>
                </span>
              </b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <shipping-address :order="order"></shipping-address>
              <shipping-address-panel id="shipping-address-panel" width="70%"
                                      v-if="order.id"
                                      :address="order.shipping"
                                      :order_id="order.id"
                                      :visible="shippingPanel"
                                      @hidden="shippingPanel = false"
                                      @update="updateShippingAddress"
              ></shipping-address-panel>
            </b-card-body>
          </b-card>

          <b-card
              no-body
              v-if="order.billing"
          >
            <b-card-header>
              <b-card-title class="row align-content-center w-100">
                <span class="col-auto align-self-center flex-grow-1">{{ $t('Billing address') }}</span>
                <span class="col-auto align-self-center flex-grow-0 px-0">
                  <b-button class="btn-icon rounded-circle" variant="outline-primary" size="sm"
                            @click="billingPanel = true"
                  >
                    <feather-icon icon="EditIcon"></feather-icon>
                  </b-button>
                </span>
              </b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <billing-address :address="order.billing"></billing-address>
              <billing-address-panel id="billing-address-panel" width="70%"
                                     v-if="order.id"
                                     :address="order.billing"
                                     :order_id="order.id"
                                     :visible="billingPanel"
                                     @hidden="billingPanel = false"
                                     @update="updateBillingAddress"
              ></billing-address-panel>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

  </section>

</template>

<script>

import {
  BCard, BRow, BCol, BCardBody, BBreadcrumb, BCardHeader, BCardTitle, BOverlay,
  BFormTextarea, BFormGroup, BButton
} from 'bootstrap-vue';
import ProductsList from "@/views/cloudcart/orders/Products/ProductsList";
import CustomerInfo from "@/views/cloudcart/orders/Customer/CustomerInfo";
import ShippingAddress from "@/views/cloudcart/orders/Address/ShippingAddress";
import {mapActions} from "vuex";
import CNavbar from "@/views/cloudcart/_elements/CNavbar";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";
import Price from "@/views/cloudcart/_elements/Price";
import OrderHistory from "@/views/cloudcart/orders/History/OrderHistory";
import BillingAddress from "@/views/cloudcart/orders/Address/BillingAddress";
import ShippingAddressPanel from "@/views/cloudcart/orders/Address/ShippingAddress/ShippingAddressPanel";
import BillingAddressPanel from "@/views/cloudcart/orders/Address/BillingAddress/BillingAddressPanel";

export default {
  components: {
    Price,
    ProductsList,
    BCard, BRow, BCol, BCardBody, BBreadcrumb, CustomerInfo,
    ShippingAddress, BCardHeader, BCardTitle, CNavbar, BOverlay,
    BFormTextarea, BFormGroup, BButton,
    OrderHistory, BillingAddress, ShippingAddressPanel, BillingAddressPanel,
    CVueSelect
  },
  name: "OrderView",
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$t("Dashboard"),
          to: {name: "dashboard-ecommerce"}
        },
        {
          text: this.$t("Orders"),
          to: {name: "orders"}
        },
        {
          text: this.$t("Order #{id}", {id: this.$route.params.id}),
          active: true
        }
      ],
      orderLoaded: false,
      selectedPaymentMethod: null,
      selectedDeliveryMethod: null,
      shippingPanel: false,
      billingPanel: false,
      paymentMethods: [
        {title: this.$t('Card'), value: "card"},
        {title: this.$t('Bank wire'), value: "bank"},
        {title: this.$t('Payment on delivery'), value: "cod"},
      ],
      deliveryMethods: [
        {title: this.$t('Econt'), value: "econt"},
        {title: this.$t('Speedy'), value: "speedy"},
        {title: this.$t('In shop'), value: "marketplace"},
      ]
    }
  },
  created() {
    this.fetchOrder({id: this.$route.params.id})
        .finally(() => {
          this.orderLoaded = true;
        })
  },
  computed: {
    order() {
      return this.$store.getters['orders/order'];
    }
  },
  methods: {
    ...mapActions('orders', ['fetchOrder', 'updateOrderAddress', 'updateOrderBilling']),
    updateShippingAddress(address) {
      this.updateOrderAddress(address);
    },
    updateBillingAddress(address) {
      this.updateOrderBilling(address);
    }
  }
}
</script>