<template>
  <b-sidebar
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
      bg-variant="light"
  >
    <template #header="{hide}">
      <c-sidebar-header @close="hide">
        <b-button variant="secondary" class="mr-2" @click="hide">{{ $t('Cancel') }}</b-button>
        <b-button variant="primary" @click="saveAddress" :disabled="saveLoading">
          <b-spinner small v-if="saveLoading"></b-spinner>
          {{ $t('Save') }}</b-button>
      </c-sidebar-header>
    </template>
    <div class="p-2">
      <b-overlay :show="showOverlay" rounded="sm" @shown="onOverlayShown" @hidden="onOverlayHidden">
        <shipping-address-form :id="address.id" @input="v => lazyAddress = v"
                               :order_id="order_id"></shipping-address-form>
      </b-overlay>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BOverlay, BButton, BSpinner} from "bootstrap-vue";
import CSidebarHeader from "@/views/cloudcart/_elements/CSidebarHeader";
import ShippingAddressForm from "@/views/cloudcart/orders/Address/ShippingAddress/ShippingAddressForm";
import {mapActions} from "vuex";

export default {
  name: "ShippingAddressPanel",
  components: {
    BSidebar, BOverlay, BButton, BSpinner,
    CSidebarHeader, ShippingAddressForm
  },
  props: {
    address: {
      type: Object,
      required: false,
      default: () => {
        return {id: null}
      }
    },
    order_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showOverlay: false,
      lazyAddress: null,
      saveLoading: false
    }
  },
  methods: {
    ...mapActions('shipping', ['store']),
    onOverlayShown() {
    },
    onOverlayHidden() {
    },
    saveAddress() {
      this.saveLoading = true;
      this.store({
        order_id: this.order_id,
        shipping: this.lazyAddress
      }).then(response => {
        this.$emit('update', response.data);
        this.$emit('hidden');
      }).finally(() => {
        this.saveLoading = false;
      })
    }
  }
}
</script>